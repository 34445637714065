<template>
  <v-container fluid class="order-details-container px-2" style="margin-top: 60px; margin-bottom: 100px;">
    <v-card v-if="loading" class="loading-card">
      <v-skeleton-loader type="article" />
    </v-card>

    <v-card v-else class="order-card">
      <v-card-title class="headline primary white--text">
        Detalhes do Pedido
        <v-spacer></v-spacer>
        <v-chip :color="status.color" dark>
          <v-icon left>{{ status.icon }}</v-icon>
          {{ status.text }}
        </v-chip>
      </v-card-title>

      <!-- Adicione esta seção logo após o v-card-title -->
      <v-card-text v-if="isOrderInProgress" class="pa-0">
        <div class="progress-container">
          <div 
            class="progress-bar" 
            :style="{ width: `${progressValue}%`, backgroundColor: progressColor }"
          ></div>
        </div>
        <p class="text-center my-2" :class="progressTextColor">{{ progressMessage }}</p>
      </v-card-text>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="8">
            <v-card flat class="mb-4">
              <v-card-title class="subtitle-1">
                <v-icon left color="primary">mdi-information-outline</v-icon>
                Informações do Pedido
              </v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12" sm="6">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>Número do Pedido</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">{{ order.hash }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>Data e Hora</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">
                          {{ formatDate(order.created_at) }} {{ formatDateToTime(order.created_at) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                  <v-col cols="12" sm="6" v-if="order?.table_name">
                    <v-list-item two-line>
                      <v-list-item-content>
                        <v-list-item-subtitle>Mesa</v-list-item-subtitle>
                        <v-list-item-title class="font-weight-medium">{{ order.table_name }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card flat class="mb-4">
              <v-card-title class="subtitle-1">
                <v-icon left color="primary">mdi-format-list-bulleted</v-icon>
                Itens do Pedido
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    v-for="(product, i) in order.products"
                    :key="i"
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <order-detail-product-card :product="product"></order-detail-product-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>

            <v-card flat v-if="order?.address || order?.dining_table || (!order?.address && !order?.dining_table)">
              <v-card-title class="subtitle-1">
                <v-icon left color="primary">mdi-map-marker</v-icon>
                Informações de Entrega
              </v-card-title>
              <v-card-text>
                <div v-if="order?.address">
                  <p><strong>Endereço:</strong> {{ order.address?.street }}, {{ order.address?.number }}</p>
                  <p>{{ order.address?.district }} - {{ order.address?.city }} - {{ order.address?.state }}</p>
                  <p class="caption">{{ order.address?.name }}</p>
                </div>
                <div v-else-if="order?.dining_table">
                  <p><strong>Origem do pedido:</strong> {{ order?.dining_table?.name }}</p>
                </div>
                <div v-else>
                  <p><strong>RETIRAR PRESENCIALMENTE</strong></p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col cols="12" md="4">
            <v-card flat class="mb-4">
              <v-card-title class="subtitle-1">
                <v-icon left color="primary">mdi-cash-multiple</v-icon>
                Resumo de Valores
              </v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>Subtotal</v-list-item-content>
                    <v-list-item-action>{{ formatMoney(order.amount - order.tax) }}</v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="!order?.dining_table_id">
                    <v-list-item-content>Taxa de entrega</v-list-item-content>
                    <v-list-item-action>{{ order.tax > 0 ? formatMoney(order.tax) : "Grátis" }}</v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="order?.debit_transaction?.amount" class="success--text">
                    <v-list-item-content>Crédito utilizado</v-list-item-content>
                    <v-list-item-action>- {{ formatMoney(order?.debit_transaction?.amount) }}</v-list-item-action>
                  </v-list-item>
                  <v-list-item v-if="order.coupon_id" class="success--text">
                    <v-list-item-content>Cupom</v-list-item-content>
                    <v-list-item-action>- {{ formatMoney(order.discount_amount) }}</v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item class="font-weight-bold">
                    <v-list-item-content>Total</v-list-item-content>
                    <v-list-item-action>{{ formatMoney(order.paid_amount) }}</v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>

            <v-card flat v-if="order?.credit_transaction?.amount" class="mb-4">
              <v-card-title class="subtitle-1">
                <v-icon left color="primary">mdi-gift</v-icon>
                Bonificação
              </v-card-title>
              <v-card-text>
                <p v-if="order?.credit_transaction?.status == 'approved'">
                  <strong>Cashback:</strong> Esse pedido gerou {{ formatMoney(order.credit_transaction.amount) }} de cashback
                </p>
                <p v-else>
                  <strong>Cashback:</strong> Seu crédito será acrescido em {{ formatMoney(order.credit_transaction.amount) }} mediante conclusão do pedido
                </p>
              </v-card-text>
            </v-card>

            <v-btn
              v-if="theme.phone && !theme.hide_whatsapp_share"
              color="green"
              dark
              @click="sendWhatsapp"
              rounded
              block
              class="mt-4"
            >
              <v-icon left>mdi-whatsapp</v-icon>
              Salvar no WhatsApp
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import PageTitle from "@/components/layouts/PageTitle";
import OrderDetailProductCard from "@/components/cards/OrderDetailProductCard";
import { mapState, mapActions } from "vuex";

export default {
  name: "orders-show",
  components: {
    PageTitle,
    OrderDetailProductCard,
  },
  data() {
    return {
      orderId: this.$route.params.id,
      poolInterval: null,
    };
  },
  async created() {
    if (this.order.id != this.orderId) {
      await this.getOrder({ id: this.orderId });
    }

    if (this.order.status != 'finished') {
      this.poolInterval = setInterval(async () => {
        await this.orderStatus();
      }, 15000);
      setTimeout(() => { clearInterval(this.poolInterval) }, 36000000);
    }
  },
  async beforeDestroy() {
    clearInterval(this.poolInterval);
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      theme: (state) => state.theme.theme,
      orders: (state) => state.orders.orders,
      order: (state) => state.orders.order,
      loading: (state) => state.orders.loading,
    }),
    status() {
      return this.getOrderStatus(this.order.status, this.order?.address);
    },
    isOrderInProgress() {
      return ['pending', 'accepted', 'on_the_way'].includes(this.order.status);
    },
    
    progressValue() {
      switch (this.order.status) {
        case 'pending':
          return 25;
        case 'accepted':
          return 50;
        case 'on_the_way':
          return 75;
        default:
          return 0;
      }
    },

    progressColor() {
      switch (this.order.status) {
        case 'pending':
          return '#FFA000'; // cor de aviso (amarelo)
        case 'accepted':
          return '#2196F3'; // cor de informação (azul)
        case 'on_the_way':
          return '#4CAF50'; // cor de sucesso (verde)
        default:
          return '#9E9E9E'; // cor cinza
      }
    },

    progressTextColor() {
      return `${this.progressColor}--text`;
    },

    progressMessage() {
      switch (this.order.status) {
        case 'pending':
          return 'Aguardando confirmação do estabelecimento...';
        case 'accepted':
          return 'Seu pedido está sendo preparado...';
        case 'on_the_way':
          return this.order?.address ? 'Seu pedido está a caminho...' : 'Seu pedido está disponível para retirada...';
        default:
          return '';
      }
    }
  },
  methods: {
    ...mapActions(["getOrder", "fetchOrderStatus"]),
    async orderStatus() {
      let status = await this.fetchOrderStatus({ id: this.orderId });

      if (status == "finished") {
        clearInterval(this.poolInterval);
      }
    },
    sendWhatsapp() {
      if (!this.theme.phone) return false;

      let paymentMethod = {
        'credit_card': 'Cartão de crédito',
        'debit_card': 'Cartão de débito',
        'money': 'Dinheiro',
        'pix': 'PIX',
      };

      let message = `Confirmação de Pedido *${this.theme.siteName}* (Meodelivery)%0a%0a`;
      message += `*Pedido*: ${this.order.hash}%0a`;
      message += `*Data*: ${this.formatDate(this.order.created_at)}%0a`;
      message += `*Horário*: ${this.formatDateToTime(
        this.order.created_at
      )}%0a`;
      // message += `*Tipo de entrega*:  %0a`;
      // message += `*Tempo estimado*: %0a`;
      message += "----------------------------%0a";
      message += `*Nome*: ${this.user.name}%0a`;
      message += `*Fone*: ${this.user.phone}%0a`;
      if (this.order?.address) {
        message += `*Endereço*: ${this.order.address?.city}, ${this.order.address?.street}, ${this.order.address?.number}%0a`;
        message += `*Bairro*: ${this.order.address?.district}%0a`;
        message += `*Complemento*: %0a`;
      } else {
        message += `**RETIRAR PRESENCIALMENTE** %0a`;
      }

      for (let i in this.order.products) {
        let product = this.order.products[i];

        message += "----------------------------%0a";
        message += `*- ${product.quantity}x ${product.name}* %0a`;

        for (let compositionType in product.selectedComposition) {
          let composition = product.selectedComposition[compositionType];
          message += `-- ${compositionType} %0a`;

          if (composition instanceof Array) {
            for (let j in composition) {
              let option = composition[j];

              message += `--- ${option.name} `;

              if (option.additional_price) {
                message += `${this.formatMoney(option.additional_price)}`;
              }

              message += "%0a";
            }
          } else {
            message += `--- ${composition.name} `;

            if (composition.additional_price) {
              message += `${this.formatMoney(composition.additional_price)}`;
            }

            message += "%0a";
          }
        }

        message += `*Obs:* ${product.observation ?? ""}%0a`;
        message += "----------------------------%0a";
      }

      message += `*Itens*: ${this.formatMoney(
        this.order.amount - this.order.tax
      )}%0a`;
      message += `*Entrega*: ${this.formatMoney(this.order.tax)}%0a`;
      message += `*Desconto*: ${this.formatMoney(
        this.order.discount_amount
      )}`;

      if (this.order?.debit_transaction?.amount) {
        message += `%0a*Créditos utilizados*: ${this.formatMoney(
          this.order?.debit_transaction?.amount
        )}`;
      }

      message += `%0a%0a*TOTAL*: ${this.formatMoney(this.order.paid_amount)}%0a`;

      paymentMethod = paymentMethod[this.order.payment_method];

      message += `*Forma de pagamento*: ${paymentMethod}\n`;

      if (this?.order?.change_value ?? null) {
          message += `*Valor de troco*: ${this.order.change_value}\n`;
      }

      window.open(`https://wa.me/55${this.theme.phone}?text=${message}`);
    },
  },
};
</script>

<style scoped>
.order-details-container {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.order-card {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1) !important;
}

.loading-card {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container {
  width: 100%;
  height: 4px;
  background-color: #E0E0E0;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-size: 40px 40px;
  animation: stripes 1s linear infinite;
}

@keyframes stripes {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 40px 0;
  }
}
</style>
